jQuery(function($){
	// single carousel
	// $('.item-carousel').slick();

	// carousel with multiple items
	$('#accreditation').slick({
	  infinite: true,
	  slidesToShow: 4,
	  slidesToScroll: 4,
	  centerPadding: '60px',
	  responsive: [
	    {
	      breakpoint: 768,
	      settings: {
	        arrows: true,
	        centerMode: true,
	        centerPadding: '40px',
	        slidesToShow: 3
	      }
	    }
	  ]
	});
});
